import { useRef } from "react";
import { motion, useInView } from "framer-motion";

const DividerLine = () => {
    const ref = useRef<null | HTMLDivElement>(null);
    const isInView = useInView(ref);

    return (
        <div className="experience__divider divider" ref={ref}>
            <motion.span
                style={{
                    width: isInView ? "50%" : "30%",
                    transition: "all 1s ease-out",
                }}
                className="divider__line"
            ></motion.span>
        </div>
    );
};

export default DividerLine;
