import { useState } from "react";
import { useDisableBodyScroll } from "./utils/utils";
import Header from "./components/header/Header";
import Hero from "./sections/hero/Hero";
import Experience from "./sections/experience/Experience";
import Skills from "./sections/skills/Skills";
import Projects from "./sections/projects/Projects";
import Contacts from "./sections/contacts/Contacts";
import Footer from "./components/footer/Footer";
import Modal from "./components/modal/Modal";

function App() {
    // Local state
    const [ismodalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState("");

    // Disable body scroll - Function
    useDisableBodyScroll(ismodalOpen);

    return (
        <>
            <Header />

            <div className="yellow-bg"></div>

            <main>
                <Hero />
                <Experience />
                <Skills />
                <Projects />
                <Contacts />
            </main>

            <Footer
                dispatchState={setModalOpen}
                dispatchContent={setModalContent}
            />

            {ismodalOpen && (
                <Modal
                    state={ismodalOpen}
                    dispatch={setModalOpen}
                    content={modalContent}
                />
            )}
        </>
    );
}

export default App;
