import { useState } from "react";
import { useTranslation } from "react-i18next";
import Project from "../../components/project/Project";
import TitleWrapper from "../../components/titleWrapper/TitleWrapper";
import { projectsCats } from "../../data/data";
import { countProjectsByCategory } from "../../utils/utils";

const Projects = () => {
    // Internazionalization
    const { i18n, t } = useTranslation();
    const locale = i18n.language;

    // Local state management
    const [activeCategory, setActiveCategory] = useState("All");

    return (
        <section className="projects mb-xl" id="projects">
            <div className="projects__wrapper mw">
                <div className="section section-common mb-s">
                    <TitleWrapper
                        sectionTitle={t("projects_menu")}
                        locale={locale}
                    />

                    <div className="section__upper-wrapper upper-wrapper">
                        <div className="upper-wrapper__row mw">
                            <ul>
                                {projectsCats(t).map((category, index) => {
                                    const isActiveCategoryMatching =
                                        activeCategory === category.id;
                                    return (
                                        <li
                                            className={
                                                isActiveCategoryMatching
                                                    ? "active"
                                                    : ""
                                            }
                                            key={index}
                                            onClick={() =>
                                                setActiveCategory(category.id)
                                            }
                                        >
                                            {`${category.name}`}
                                            <sup className="count">
                                                {countProjectsByCategory(
                                                    t,
                                                    category.id
                                                )}
                                            </sup>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>

                <div className="projects__content mb-s">
                    <div className="projects__cards">
                        <Project state={activeCategory} />
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Projects;
