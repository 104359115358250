const Alert = ({
    callbackOnClick,
}: {
    callbackOnClick: React.MouseEventHandler<HTMLButtonElement>;
}) => {
    return (
        <button onClick={callbackOnClick} className="input__alert alert">
            <span className="alert__icon icon icon-alert"></span>
        </button>
    );
};

export default Alert;
