import { useTranslation } from "react-i18next";
import { experienceCards } from "../../data/data";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const ExperienceCard = () => {
    // Internazionalization
    const { t } = useTranslation();

    return (
        <>
            {experienceCards(t).map((experience, index) => (
                <div className="experience__card card" key={index}>
                    <figure>
                        <LazyLoadImage
                            src={experience.img}
                            alt={experience.title}
                            effect="blur"
                            loading="lazy"
                        />
                        <div className="card__overlay">
                            <div className="card__content">
                                <h3 className="card__title">
                                    {experience.title}
                                </h3>
                                <p>{`${experience.description}`}</p>
                            </div>
                        </div>
                    </figure>
                </div>
            ))}
        </>
    );
};

export default ExperienceCard;
