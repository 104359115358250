import { motion } from "framer-motion";
import { skillsData } from "../../data/data";
import SkillsList from "../skillsList/SkillsList";
import { useMatchMedia } from "../../utils/utils";

const Skill = ({
    skillState,
    handleCallbackOnClick,
}: {
    skillState: string;
    handleCallbackOnClick: Function;
}) => {
    const isDesktopResolution = useMatchMedia("(min-width:1024px)", true);
    let variants = {};
    isDesktopResolution
        ? (variants = {
              open: { display: "flex", x: 0, opacity: 1 },
              closed: { x: "100%", opacity: 0, display: "none" },
          })
        : (variants = {
              open: { display: "flex", y: 0, opacity: 1 },
              closed: { y: "-100%", opacity: 0, display: "none" },
          });

    return (
        <>
            {skillsData?.map((skillObj, index) => {
                const isSkillMatching: boolean = skillState === skillObj.title;
                return (
                    <div
                        onClick={() => handleCallbackOnClick(skillObj.title)}
                        className={`skills__row mw skill ${
                            isSkillMatching ? "active" : ""
                        }`}
                        key={index}
                    >
                        <div className="skill__col">
                            <h3 className="display-1 notranslate">
                                {skillObj.title}
                            </h3>
                        </div>

                        <motion.div
                            animate={isSkillMatching ? "open" : "closed"}
                            transition={{ duration: 0.5 }}
                            variants={variants}
                            className="skill__col skill__array"
                        >
                            <SkillsList skillsArray={skillObj.skills} />
                        </motion.div>

                        <div className="skill__col">
                            <svg
                                width="31"
                                height="29"
                                viewBox="0 0 31 29"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M0.68335 25.8118L27.2437 0.491025C29.0179 -1.03842 31.2805 1.34072 29.6678 3.0401L3.1075 28.3609C3.1075 28.3609 1.85137 29.7204 0.558495 28.3609C-0.56445 27.0014 0.68335 25.8118 0.68335 25.8118Z"
                                    fill="white"
                                />
                                <path
                                    d="M25.8785 28.3609L1.79865 3.0401C0.352484 1.34072 2.84809 -1.03842 4.47251 0.491025L16.5124 13.1514L28.5524 25.8118C28.5524 25.8118 29.8536 27.0014 28.4275 28.3609C27.0098 29.5505 25.8785 28.3609 25.8785 28.3609Z"
                                    fill="white"
                                />
                            </svg>
                        </div>
                    </div>
                );
            })}
        </>
    );
};

export default Skill;
