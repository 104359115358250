import { useState } from "react";
import Menu from "../menu/Menu";
import MenuToggle from "../menuToggle/MenuToggle";
import LangToggle from "../langToggle/LangToggle";
import { useDisableBodyScroll } from "../../utils/utils";

const Header = () => {
    // Local state management
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // Disable body scroll - Function
    useDisableBodyScroll(isMenuOpen);

    return (
        <header className={isMenuOpen ? "header menu-open" : "header"}>
            <div className="header__row">
                <div className="header__col">
                    <h1>
                        <span>Marco Brambilla</span>
                    </h1>
                </div>
                <div className="header__col">
                    <LangToggle />
                    <MenuToggle
                        state={isMenuOpen}
                        menuToggleCallbackOnClick={setIsMenuOpen}
                    />
                </div>
            </div>

            <Menu state={isMenuOpen} menuCallbackOnClick={setIsMenuOpen} />
        </header>
    );
};

export default Header;
