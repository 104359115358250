const SkillsList = ({ skillsArray }: { skillsArray: Array<string> }) => {
    return (
        <ul>
            {skillsArray?.map((skill) => {
                return (
                    <li className="notranslate" key={skill}>
                        {skill}
                    </li>
                );
            })}
        </ul>
    );
};

export default SkillsList;
