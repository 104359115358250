import { Swiper, SwiperSlide } from "swiper/react";
import { Scrollbar, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import { filterProjectsByCategory, useMatchMedia } from "../../utils/utils";
import { useTranslation } from "react-i18next";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";

const Project = ({ state }: { state: string }) => {
    // Internazionalization
    const { t } = useTranslation();

    const filteredProjects = filterProjectsByCategory(t, state);
    const sortedProjects = filteredProjects.sort((a, b) => b.id - a.id);
    const isDesktopResolution = useMatchMedia("(min-width:1024px)", true);
    const isNavigationEnabled =
        sortedProjects.length > 3 ? "" : "navigation-disabled";

    return isDesktopResolution ? (
        <Swiper
            slidesPerView={3}
            spaceBetween={20}
            scrollbar={{
                draggable: true,
                hide: false,
            }}
            modules={[Scrollbar, Navigation]}
            navigation
            watchOverflow={true}
            breakpoints={{
                1280: {
                    slidesPerView: 3.8,
                },
            }}
            className={`mySwiper ${isNavigationEnabled}`}
        >
            {sortedProjects.map((project) => {
                return (
                    <SwiperSlide
                        className="projects__card card"
                        key={project.id}
                    >
                        <a
                            href={project.link}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={`${t("aria_project_link")}${
                                project.title
                            }`}
                        >
                            <figure>
                                <LazyLoadImage
                                    src={project.img}
                                    alt={project.title}
                                    effect="blur"
                                    loading="lazy"
                                />
                                <div className="card__overlay">
                                    <div className="card__content">
                                        <div className="card__upper">
                                            <span>{project.category}</span>

                                            <svg
                                                width="32"
                                                height="32"
                                                viewBox="0 0 32 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5.3827 2.12072L31.8226 0.318894L30.0249 27.1791L25.4516 26.8738L26.6674 8.72247L3.96465 31.4681L3.94322 31.4466L0.726494 28.2289L23.4303 5.4833L5.68905 6.69301L5.3827 2.12072Z"
                                                    fill="#1A1A1A"
                                                />
                                            </svg>
                                        </div>
                                        <div className="card__bottom">
                                            <h3 className="card__title">
                                                {project.title}
                                            </h3>
                                            {project.agency && (
                                                <span className="card__agency">
                                                    Agency:{" "}
                                                    <strong>
                                                        {project.agency}
                                                    </strong>
                                                </span>
                                            )}
                                            <p className="card__description">
                                                {`${project.description}`}
                                            </p>
                                            <div className="card__skills">
                                                {Array.from(project.skills).map(
                                                    (
                                                        skill: string,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <span
                                                                key={index}
                                                                className="card__skill"
                                                            >
                                                                {skill}
                                                            </span>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                        </a>
                    </SwiperSlide>
                );
            })}
        </Swiper>
    ) : (
        <div className="projects__row">
            {sortedProjects.map((project) => {
                return (
                    <div className="projects__card card" key={project.id}>
                        <a
                            href={project.link}
                            target="_blank"
                            rel="noreferrer"
                            aria-label={`${t("aria_project_link")}${
                                project.title
                            }`}
                        >
                            <figure>
                                <LazyLoadImage
                                    src={project.img}
                                    alt={project.title}
                                    effect="blur"
                                    loading="lazy"
                                />
                                <div className="card__overlay">
                                    <div className="card__content">
                                        <div className="card__upper">
                                            <span>{project.category}</span>

                                            <svg
                                                width="32"
                                                height="32"
                                                viewBox="0 0 32 32"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M5.3827 2.12072L31.8226 0.318894L30.0249 27.1791L25.4516 26.8738L26.6674 8.72247L3.96465 31.4681L3.94322 31.4466L0.726494 28.2289L23.4303 5.4833L5.68905 6.69301L5.3827 2.12072Z"
                                                    fill="#1A1A1A"
                                                />
                                            </svg>
                                        </div>
                                        <div className="card__bottom">
                                            <h3 className="card__title">
                                                {project.title}
                                            </h3>
                                            {project.agency && (
                                                <span className="card__agency">
                                                    Agency:{" "}
                                                    <strong>
                                                        {project.agency}
                                                    </strong>
                                                </span>
                                            )}
                                            <p className="card__description">
                                                {`${project.description}`}
                                            </p>

                                            <div className="card__skills">
                                                {Array.from(project.skills).map(
                                                    (
                                                        skill: string,
                                                        index: number
                                                    ) => {
                                                        return (
                                                            <span
                                                                key={index}
                                                                className="card__skill"
                                                            >
                                                                {skill}
                                                            </span>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </figure>
                        </a>
                    </div>
                );
            })}
        </div>
    );
};

export default Project;
