import { Link } from "react-scroll";
import { menuItems } from "../../data/data";
import { useTranslation } from "react-i18next";

const Menu = ({
    state,
    menuCallbackOnClick,
}: {
    state?: boolean;
    menuCallbackOnClick?: Function;
}) => {
    // Internazionalization
    const { t } = useTranslation();

    return (
        <nav className={state ? "menu menu--open" : "menu"}>
            <span className="icon logo-icon"></span>
            <ul>
                {menuItems(t).map((item, index) => {
                    return (
                        <li className="outlined-text" key={index}>
                            <Link
                                onClick={() =>
                                    menuCallbackOnClick
                                        ? menuCallbackOnClick(!state)
                                        : null
                                }
                                href="#"
                                activeClass="active"
                                to={`${item.label}`}
                                offset={item.label === "about" ? -101 : -40}
                                spy={true}
                                smooth={true}
                                duration={500}
                            >
                                {`${item.menu}`}
                            </Link>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default Menu;
