import React, { useState, useRef } from "react";
import Input from "../ui/Input";
import TextArea from "../ui/Textarea";
import Alert from "../ui/Alert";
import {
    checkNameValidity,
    checkEmailValidity,
    checkMessageValidity,
    showToastAlert,
} from "../../utils/utils";
import { useTranslation } from "react-i18next";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactForm = () => {
    // Use refs
    const form = useRef<HTMLFormElement | null>(null);

    // Internazionalization
    const { t } = useTranslation();

    // Input values store
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [message, setMessage] = useState("");
    const [checkbox, setCheckbox] = useState(false);

    // Input errors store
    const [nameError, setNameError] = useState<string | Function>("");
    const [emailError, setEmailError] = useState<string | Function>("");
    const [messageError, setMessageError] = useState<string | Function>("");

    // Check if submit button is disabled
    const isSubmitDisabled =
        name !== "" &&
        email !== "" &&
        message !== "" &&
        checkbox &&
        nameError === "" &&
        emailError === "" &&
        messageError === ""
            ? false
            : true;

    const serviceID: string | undefined = process.env.REACT_APP_EMAILJS_SERVICE_ID;
    const publicKey: string | undefined = process.env.REACT_APP_EMAILJS_PUBLIC_KEY;

    // Email sender
    const sendEmail = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (form.current && !isSubmitDisabled) {
            emailjs
                .sendForm(
                    `${serviceID}`,
                    "main_template",
                    form.current as HTMLFormElement,
                    `${publicKey}`
                )
                .then(
                    () => {
                        toast.success(t("form_submit_success"));
                        setName("");
                        setEmail("");
                        setMessage("");
                        setCheckbox(false);
                        form.current && form.current.reset();
                    },
                    (error) => {
                        console.log(error);
                        toast.error(t("form_submit_error"));
                    }
                );
        }
    };

    return (
        <form ref={form} className="contact-form" onSubmit={sendEmail}>
            <ToastContainer
                position="bottom-right"
                autoClose={5000}
                newestOnTop={true}
                rtl={false}
                pauseOnFocusLoss
                draggable
                theme="colored"
                limit={1}
            />

            <div className="input__wrapper">
                <Input
                    inputType="text"
                    inputId="name"
                    inputName="name"
                    inputValue={name}
                    inputPlaceholder={t("form_input_name")}
                    autoComplete="on"
                    callbackOnChange={(e: string) => setName(e)}
                    callbackOnBlur={() =>
                        setNameError(checkNameValidity(name, t))
                    }
                />

                {nameError !== "" && (
                    <Alert
                        callbackOnClick={() => showToastAlert(`nameError`)}
                    />
                )}
            </div>

            <div className="input__wrapper">
                <Input
                    inputType="email"
                    inputName="email"
                    inputId="email"
                    inputValue={email}
                    inputPlaceholder={t("form_input_email")}
                    autoComplete="on"
                    callbackOnChange={(e: string) => setEmail(e)}
                    callbackOnBlur={() =>
                        setEmailError(checkEmailValidity(email, t))
                    }
                />

                {emailError !== "" && (
                    <Alert
                        callbackOnClick={() => showToastAlert(`${emailError}`)}
                    />
                )}
            </div>

            <div className="input__wrapper textarea__wrapper">
                <TextArea
                    textareaName="message"
                    textareaCols="1"
                    textareaRows="10"
                    textareaPlaceholder={t("form_input_message")}
                    inputValue={message}
                    callbackOnChange={(e: string) => setMessage(e)}
                    callbackOnBlur={() =>
                        setMessageError(checkMessageValidity(message, t))
                    }
                />

                {messageError !== "" && (
                    <Alert
                        callbackOnClick={() =>
                            showToastAlert(`${messageError}`)
                        }
                    />
                )}
            </div>

            <div className="checkbox-container">
                <div className="checkbox bounce">
                    <div
                        onClick={() => setCheckbox(!checkbox)}
                        className={`checkbox-input ${checkbox && "checked"}`}
                    ></div>
                    <svg viewBox="0 0 21 21">
                        <polyline points="5 10.75 8.5 14.25 16 6"></polyline>
                    </svg>
                </div>
                <div className="label">
                    <p>{t("form_input_checkbox")}</p>
                </div>
            </div>

            <button
                type="submit"
                name="submit"
                className="cta submit"
                disabled={isSubmitDisabled}
            >
                <span className="submit__icon icon icon-link-white"></span>
                <span className="submit__text">{t("form_submit_button")}</span>
            </button>
        </form>
    );
};

export default ContactForm;
