import { useTranslation } from "react-i18next";
import ReactTypescriptLogo from "../../assets/svg/react-typescript-logo.svg";
import SassLogo from "../../assets/svg/sass-logo.svg";
import Menu from "../menu/Menu";

const Footer = ({
    dispatchState,
    dispatchContent,
}: {
    dispatchState: Function;
    dispatchContent: Function;
}) => {
    // Internazionalization
    const { t } = useTranslation();

    // Open privacy policy callback
    const handlePrivacyOnClick = () => {
        dispatchState(true);
        dispatchContent(t("privacy_text"));
    };

    // Open cookie policy callback
    const handleCookieOnClick = () => {
        dispatchState(true);
        dispatchContent(t("cookie_text"));
    };

    return (
        <footer className="footer">
            <Menu />

            <div className="footer__inside">
                <span className="footer__credits h4">
                    {t("developed_with")}
                </span>
                <div className="footer__logos">
                    <div className="footer__logo">
                        <img
                            src={ReactTypescriptLogo}
                            width="68"
                            height="68"
                            alt="Developed with React JS + TypeScript"
                        />
                    </div>
                    <div className="footer__logo">
                        <img
                            src={SassLogo}
                            width="68"
                            height="68"
                            alt="Developed with SASS"
                        />
                    </div>
                </div>

                <div className="footer__copyright">
                    <p>
                        <span
                            className="footer__policy"
                            aria-label={t("aria_privacy_open")}
                            onClick={handlePrivacyOnClick}
                        >
                            {" "}
                            Privacy Policy
                        </span>{" "}
                        -
                        <span
                            className="footer__policy"
                            aria-label={t("aria_cookie_open")}
                            onClick={handleCookieOnClick}
                        >
                            {" "}
                            Cookie Policy
                        </span>{" "}
                        -
                        <span className="footer__policy">
                            <button
                                className="iubenda-cs-preferences-link"
                                style={{ cursor: "pointer" }}
                            >
                                Preferenze Cookie
                            </button>
                        </span>
                    </p>
                    <p>
                        Copyright © 2023 Marco Brambilla - {t("copyright_text")}
                    </p>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
