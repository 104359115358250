import parse from "html-react-parser";
import { useTranslation } from "react-i18next";

const Modal = ({
    state,
    content,
    dispatch,
}: {
    state: boolean;
    content: string;
    dispatch: Function;
}) => {
    // Internazionalization
    const { t } = useTranslation();

    return (
        <div className={`modal__bg ${state && "modal__open"}`}>
            <div className="modal__container relative">
                <div className="modal__body">{parse(content)}</div>
                <div className="modal__footer">
                    <button
                        className="modal__cta cta"
                        onClick={() => dispatch(false)}
                    >
                        <span>{t("policy_close")}</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Modal;
