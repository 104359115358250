import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { motion, useScroll, useTransform } from "framer-motion";
import TitleWrapper from "../../components/titleWrapper/TitleWrapper";
import Skill from "../../components/skill/Skill";
import DividerLine from "../../components/dividerLine/DividerLine";

const Skills = () => {
    // Use refs
    let ref = useRef<null | HTMLElement>(null);

    // Internazionalization
    const { t } = useTranslation();

    // Framer Motion configurations
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start start", "end start"],
    });
    const y = useTransform(scrollYProgress, [0, 1], ["0%", "-50%"]);

    // Local state management
    const [activeSkill, setActiveSkill] = useState("Frontend");

    // Handle skill on click function
    const skillOnClick = (skill: string) => {
        let updateSkill: null | string = null;
        activeSkill === skill ? (updateSkill = "") : (updateSkill = skill);
        setActiveSkill(updateSkill);
    };

    return (
        <section className="skills mb-xl" id="skills" ref={ref}>
            <div className="skills__wrapper mw">
                <div className="section section-common">
                    <TitleWrapper sectionTitle={"Skills"} />

                    <div className="section__upper-wrapper upper-wrapper">
                        <div className="upper-wrapper__row mw">
                            <p>{t("skills_description")}</p>
                        </div>
                    </div>
                </div>

                <div className="skills__content mb-xs">
                    <Skill
                        skillState={activeSkill}
                        handleCallbackOnClick={skillOnClick}
                    />

                    <motion.div style={{ y }} className="skill__icon mw">
                        <svg
                            width="104"
                            height="89"
                            viewBox="0 0 104 89"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M98.8004 73.24L98.8004 79.1355L104 79.1355L104 82.3381L98.8004 82.3381L98.8004 88.1245L95.3584 88.1245L95.3584 82.3381L90.049 82.3381L90.049 79.1355L95.3584 79.1355L95.3584 73.24L98.8004 73.24Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M76.2882 73.2399L76.2882 79.1355L81.4878 79.1355L81.4878 82.3381L76.2882 82.3381L76.2882 88.1245L72.8462 88.1245L72.8462 82.3381L67.5367 82.3381L67.5367 79.1355L72.8462 79.1355L72.8462 73.2399L76.2882 73.2399Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M53.7759 73.2399L53.7759 79.1355L58.9755 79.1355L58.9755 82.3381L53.7759 82.3381L53.7759 88.1245L50.3339 88.1245L50.3339 82.3381L45.0245 82.3381L45.0245 79.1355L50.3339 79.1355L50.3339 73.2399L53.7759 73.2399Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M31.2637 73.2399L31.2637 79.1355L36.4633 79.1355L36.4633 82.3381L31.2637 82.3381L31.2637 88.1245L27.8217 88.1245L27.8217 82.3381L22.5122 82.3381L22.5122 79.1355L27.8217 79.1355L27.8217 73.2399L31.2637 73.2399Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M8.75143 73.2399L8.75143 79.1355L13.951 79.1355L13.951 82.3381L8.75143 82.3381L8.75143 88.1245L5.30944 88.1245L5.30944 82.3381L5.05865e-07 82.3381L7.8584e-07 79.1355L5.30944 79.1355L5.30944 73.2399L8.75143 73.2399Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M98.8004 36.6199L98.8004 42.5155L104 42.5155L104 45.7181L98.8004 45.7181L98.8004 51.5045L95.3584 51.5045L95.3584 45.7181L90.049 45.7181L90.049 42.5155L95.3584 42.5155L95.3584 36.6199L98.8004 36.6199Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M76.2882 36.6199L76.2882 42.5155L81.4878 42.5155L81.4878 45.7181L76.2882 45.7181L76.2882 51.5045L72.8462 51.5045L72.8462 45.7181L67.5367 45.7181L67.5367 42.5155L72.8462 42.5155L72.8462 36.6199L76.2882 36.6199Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M53.7759 36.6199L53.7759 42.5155L58.9755 42.5155L58.9755 45.7181L53.7759 45.7181L53.7759 51.5045L50.3339 51.5045L50.3339 45.7181L45.0245 45.7181L45.0245 42.5155L50.3339 42.5155L50.3339 36.6199L53.7759 36.6199Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M31.2637 36.6199L31.2637 42.5155L36.4633 42.5155L36.4633 45.7181L31.2637 45.7181L31.2637 51.5045L27.8217 51.5045L27.8217 45.7181L22.5122 45.7181L22.5122 42.5155L27.8217 42.5155L27.8217 36.6199L31.2637 36.6199Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M8.75143 36.6199L8.75143 42.5155L13.951 42.5155L13.951 45.7181L8.75143 45.7181L8.75143 51.5045L5.30944 51.5045L5.30944 45.7181L3.70729e-06 45.7181L3.98726e-06 42.5155L5.30944 42.5155L5.30945 36.6199L8.75143 36.6199Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M98.8004 -8.43779e-05L98.8004 5.89551L104 5.89552L104 9.09805L98.8004 9.09805L98.8004 14.8845L95.3584 14.8845L95.3584 9.09805L90.049 9.09805L90.049 5.89551L95.3584 5.89551L95.3584 -8.46788e-05L98.8004 -8.43779e-05Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M76.2882 -8.6346e-05L76.2882 5.89551L81.4878 5.89551L81.4878 9.09805L76.2882 9.09805L76.2882 14.8845L72.8462 14.8845L72.8462 9.09805L67.5367 9.09805L67.5367 5.89551L72.8462 5.89551L72.8462 -8.66469e-05L76.2882 -8.6346e-05Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M53.7759 -8.83141e-05L53.7759 5.89551L58.9755 5.89551L58.9755 9.09805L53.7759 9.09805L53.7759 14.8845L50.3339 14.8845L50.3339 9.09805L45.0245 9.09805L45.0245 5.89551L50.3339 5.89551L50.3339 -8.8615e-05L53.7759 -8.83141e-05Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M31.2637 -9.02822e-05L31.2637 5.89551L36.4633 5.89551L36.4633 9.09805L31.2637 9.09805L31.2637 14.8845L27.8217 14.8845L27.8217 9.09805L22.5122 9.09805L22.5122 5.89551L27.8217 5.89551L27.8217 -9.05831e-05L31.2637 -9.02822e-05Z"
                                fill="#D0FF00"
                            />
                            <path
                                d="M8.75143 -9.22502e-05L8.75143 5.89551L13.951 5.89551L13.951 9.09805L8.75143 9.09804L8.75143 14.8845L5.30945 14.8845L5.30945 9.09804L6.90871e-06 9.09804L7.18869e-06 5.89551L5.30945 5.89551L5.30945 -9.25511e-05L8.75143 -9.22502e-05Z"
                                fill="#D0FF00"
                            />
                        </svg>
                    </motion.div>
                </div>

                <DividerLine />
            </div>
        </section>
    );
};

export default Skills;
