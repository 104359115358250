const Input = ({ ...props }) => {
    const handleCallbackInputOnChange = (
        e: React.ChangeEvent<HTMLInputElement>
    ) => {
        props.callbackOnChange(e.target.value);
    };

    return (
        <>
            <label htmlFor={props.inputId} hidden>
                {props.inputName}
            </label>
            <input
                type={props.inputType}
                name={props.inputName}
                value={props.inputValue}
                placeholder={props.inputPlaceholder}
                className={props.inputClassName}
                id={props.inputId}
                onChange={handleCallbackInputOnChange}
                onBlur={props.callbackOnBlur}
                onSubmit={props.callbackOnSubmit}
                checked={props.callbackInputChecked}
                autoComplete={props.autoComplete}
            />
        </>
    );
};

export default Input;
