const TextArea = ({ ...props }) => {
    const handleCallbackTextareaOnChange = (
        e: React.ChangeEvent<HTMLTextAreaElement>
    ) => {
        props.callbackOnChange(e.target.value);
    };

    return (
        <textarea
            name={props.textareaName}
            cols={props.textareaCols}
            rows={props.textareaRows}
            className={props.textareaClass}
            placeholder={props.textareaPlaceholder}
            onChange={handleCallbackTextareaOnChange}
            onBlur={props.callbackOnBlur}
        />
    );
};

export default TextArea;
