import { useRef } from "react";
import { motion, useScroll, useTransform, useInView } from "framer-motion";
import { useTranslation } from "react-i18next";
import { useMatchMedia } from "../../utils/utils";

const Hero = () => {
    // Use refs
    const ref = useRef<null | HTMLElement>(null);
    const isInView = useInView(ref);

    // Framer Motion configurations
    const { scrollYProgress, scrollY } = useScroll();
    const y = useTransform(scrollY, [0, 500], ["0%", "-100%"]);
    const maxWidth = useTransform(scrollY, [0, 500], ["310px", "210px"]);
    const scale = useTransform(scrollYProgress, [0, 1], [1, 4]);
    const opacity = useTransform(scrollYProgress, [1, 0], [-5, 1]);

    // Internazionalization
    const { t } = useTranslation();

    // Check resolution on window resize
    const isTabletResolution = useMatchMedia("(max-width:1024px)", true);
    const isDesktopResolution = useMatchMedia("(min-width:769px)", true);

    return (
        <section className="hero mb-xl" id="about" ref={ref}>
            <div className="hero__wrapper mw">
                <div className="hero__row">
                    <span className="h1 outlined-text notranslate">Web</span>

                    {isDesktopResolution && (
                        <div className="section">
                            <div className="section__title-wrapper title-wrapper relative">
                                <motion.h2
                                    style={{
                                        y: isInView ? "0%" : "50%",
                                        opacity: isInView ? 1 : 0,
                                        transition: "all 1s ease-out",
                                    }}
                                    className="title-wrapper__title title-wrapper__title--small"
                                >
                                    About me
                                </motion.h2>
                                <motion.span
                                    style={{ maxWidth }}
                                    className="title-wrapper__line"
                                ></motion.span>
                            </div>
                            <p>{t("about_description")}</p>
                        </div>
                    )}
                </div>

                {!isTabletResolution ? (
                    <motion.span
                        style={{ scale, opacity }}
                        className="h1 scale notranslate"
                    >
                        Developer
                    </motion.span>
                ) : (
                    <span className="h1 scale notranslate">Developer</span>
                )}
            </div>

            <motion.div style={{ y, opacity }} className="hero__decoration">
                <svg
                    width="257"
                    height="120"
                    viewBox="0 0 257 120"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M0.345994 39.047L3.98873 37.1318L43.0218 111.103L54.0586 30.2832C54.1859 29.4122 54.8532 28.6816 55.7161 28.5295C56.5791 28.3773 57.4479 28.7899 57.8736 29.6106L95.9241 101.728L107.014 20.9457C107.142 20.0746 107.809 19.3441 108.672 19.1919C109.535 19.0398 110.404 19.4523 110.829 20.273L148.88 92.3903L159.97 11.6082C160.098 10.7371 160.765 10.0066 161.628 9.8544C162.491 9.70225 163.359 10.1148 163.785 10.9355L201.836 83.0527L212.971 2.26263C213.099 1.39156 213.766 0.661012 214.629 0.508857C215.492 0.356702 216.361 0.769244 216.786 1.58994L258.783 81.2146L255.14 83.1298L216.153 9.1506L205.071 89.9784C204.943 90.8495 204.276 91.58 203.413 91.7322C202.55 91.8843 201.681 91.4718 201.256 90.6511L163.197 18.4881L152.107 99.2702C151.979 100.141 151.312 100.872 150.449 101.024C149.586 101.176 148.717 100.764 148.292 99.9429L110.241 27.8257L99.1509 108.608C99.0235 109.479 98.3562 110.209 97.4933 110.362C96.6304 110.514 95.7615 110.101 95.3359 109.28L57.2399 37.1712L46.1496 117.953C46.0223 118.824 45.355 119.555 44.4921 119.707C43.6292 119.859 42.7603 119.447 42.3347 118.626L0.345994 39.047Z"
                        fill="#1A1A1A"
                    />
                </svg>
            </motion.div>

            {!isDesktopResolution && (
                <div className="section section-mobile mw">
                    <div className="section__title-wrapper title-wrapper relative">
                        <motion.h2
                            style={{
                                y: isInView ? "0%" : "50%",
                                opacity: isInView ? 1 : 0,
                                transition: "all 1s ease-out",
                            }}
                            className="title-wrapper__title title-wrapper__title--small"
                        >
                            About me
                        </motion.h2>
                        <motion.span
                            style={{ maxWidth }}
                            className="title-wrapper__line"
                        ></motion.span>
                    </div>
                    <p>{t("about_description")}</p>
                </div>
            )}
        </section>
    );
};

export default Hero;
