const MenuToggle = ({
    state,
    menuToggleCallbackOnClick,
}: {
    state: boolean;
    menuToggleCallbackOnClick: Function;
}) => {
    return (
        <div
            className={`header__menu-toggle menu-toggle ${
                state ? "menu-open" : ""
            }`}
            onClick={() => menuToggleCallbackOnClick(!state)}
        >
            <span className="menu-toggle__line menu-toggle__line--one"></span>
            <span className="menu-toggle__line menu-toggle__line--two"></span>
            <span className="menu-toggle__line menu-toggle__line--three"></span>
        </div>
    );
};

export default MenuToggle;
