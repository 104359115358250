import { useRef } from "react";
import { motion, useInView } from "framer-motion";

const TitleWrapper = ({
    locale,
    sectionTitle,
}: {
    locale?: string;
    sectionTitle: string;
}) => {
    // Use refs
    const ref = useRef<null | HTMLDivElement>(null);
    const isInView: boolean = useInView(ref);

    return (
        <div
            className="section__title-wrapper title-wrapper relative"
            ref={ref}
        >
            <motion.h2
                style={{
                    y: isInView ? "0%" : "50%",
                    opacity: isInView ? 1 : 0,
                    transition: "all 1s ease-out",
                }}
                className={`title-wrapper__title ${locale === "it" && "it"}`}
            >
                {sectionTitle}
            </motion.h2>
            <motion.span
                style={{
                    width: isInView ? "50%" : "30%",
                    transition: "all 1s ease-out",
                }}
                className="title-wrapper__line"
            ></motion.span>
        </div>
    );
};

export default TitleWrapper;
