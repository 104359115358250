import { useRef } from "react";
import ContactForm from "../../components/contactForm/ContactForm";
import { contactsInfo } from "../../data/data";
import { useTranslation } from "react-i18next";
import IconChat from "../../assets/svg/icon-chat.svg";
import DividerLine from "../../components/dividerLine/DividerLine";
import { motion, useScroll, useTransform, useInView } from "framer-motion";
import CVIt from "../../assets/pdf/Marco_Brambilla_CV_IT.pdf";
import CVEn from "../../assets/pdf/Marco_Brambilla_CV_EN.pdf";

const Contacts = () => {
    // Use refs
    let ref = useRef<null | HTMLElement>(null);
    let divider = useRef<null | HTMLDivElement>(null);
    const isInView = useInView(divider);

    // Internazionalization
    const { t, i18n } = useTranslation();
    const locale = i18n.language;

    // Framer Motion configurations
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start start", "end start"],
    });
    const y = useTransform(scrollYProgress, [0, 1], ["0%", "-100%"]);

    // CV download
    const CVHref: string = locale === "it" ? CVIt : CVEn;

    return (
        <>
            <div
                className="contact__divider divider divider--right mb-xs"
                ref={divider}
            >
                <motion.span
                    style={{
                        width: isInView ? "50%" : "30%",
                        transition: "all 1s ease-out",
                    }}
                    className="divider__line"
                ></motion.span>
            </div>
            <section className="contacts mb-xl" id="contacts" ref={ref}>
                <div className="contacts__container">
                    <div className="contacts__row mw mb-xs">
                        <div className="contacts__col relative">
                            <motion.div
                                className="icon-zz-line-white_svg"
                                style={{ y }}
                            >
                                <svg
                                    className="svg"
                                    width="190"
                                    height="88"
                                    viewBox="0 0 190 88"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M0.159248 28.2865L2.82264 26.8862L31.3618 80.9704L39.4313 21.8788C39.5245 21.2419 40.0123 20.7078 40.6432 20.5965C41.2742 20.4853 41.9094 20.7869 42.2207 21.387L70.0414 74.1157L78.1501 15.0517C78.2432 14.4148 78.7311 13.8806 79.362 13.7694C79.9929 13.6581 80.6282 13.9598 80.9394 14.5598L108.76 67.2885L116.869 8.2245C116.962 7.58761 117.45 7.05348 118.081 6.94223C118.712 6.83098 119.347 7.13261 119.658 7.73266L147.479 60.4613L155.621 1.39148C155.714 0.754598 156.202 0.220459 156.833 0.109211C157.464 -0.00203798 158.099 0.299593 158.41 0.899648L189.116 59.1174L186.453 60.5177L157.947 6.42764L149.844 65.5251C149.751 66.162 149.263 66.6961 148.632 66.8073C148.001 66.9186 147.366 66.617 147.055 66.0169L119.228 13.2548L111.119 72.3188C111.026 72.9557 110.538 73.4898 109.907 73.6011C109.277 73.7123 108.641 73.4107 108.33 72.8106L80.5093 20.082L72.4006 79.146C72.3075 79.7829 71.8196 80.317 71.1887 80.4282C70.5578 80.5395 69.9225 80.2379 69.6113 79.6378L41.7574 26.915L33.6487 85.979C33.5555 86.6159 33.0677 87.15 32.4368 87.2613C31.8058 87.3725 31.1706 87.0709 30.8593 86.4708L0.159248 28.2865Z"
                                        fill="white"
                                    />
                                </svg>
                            </motion.div>
                            <div className="contacts__wrapper">
                                <div className="infos mb-m">
                                    <h3 className="infos__title">
                                        {t("contacts_info_title")}{" "}
                                        <span className="underline">
                                            {t("projects")}
                                        </span>
                                    </h3>
                                    {contactsInfo(t).map((info, index) => {
                                        return (
                                            <div
                                                className="infos__info mb-xs"
                                                key={index}
                                            >
                                                <span
                                                    className={`icon icon-${info.icon}`}
                                                ></span>
                                                {info.link ? (
                                                    <span>
                                                        <a
                                                            href={info.link}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            {`${info.info}`}
                                                        </a>
                                                    </span>
                                                ) : (
                                                    <span>
                                                        {" "}
                                                        {`${info.info}`}
                                                    </span>
                                                )}
                                            </div>
                                        );
                                    })}
                                </div>

                                <div className="download">
                                    <h3 className="download__title">
                                        {t("contacts_download_cv")}
                                    </h3>
                                    <button className="download__button cta">
                                        <a
                                            href={CVHref}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            Download
                                            <svg
                                                width="16"
                                                height="16"
                                                viewBox="0 0 16 16"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    d="M7.31704 11.5825C7.43876 11.6984 7.59493 11.7562 7.75051 11.7562C7.91572 11.7562 8.08123 11.6914 8.20412 11.5621L12.1418 7.44052C12.3811 7.18978 12.3724 6.79222 12.1217 6.55285C11.8709 6.31379 11.4739 6.32226 11.234 6.57329L8.41077 9.52817V0.627577C8.41077 0.280804 8.12996 0 7.78319 0C7.43642 0 7.15561 0.280804 7.15561 0.627577V9.69182L4.29152 6.95588C4.04078 6.71653 3.6438 6.72529 3.40385 6.97632C3.1645 7.22706 3.17326 7.62462 3.42399 7.86399L7.31704 11.5825Z"
                                                    fill="#1A1A1A"
                                                />
                                                <path
                                                    d="M14.9391 13.8091H0.627577C0.280804 13.8091 0 14.0902 0 14.4367C0 14.7831 0.280804 15.0642 0.627577 15.0642H14.9391C15.2858 15.0642 15.5666 14.7831 15.5666 14.4367C15.5669 14.0905 15.2861 13.8091 14.9391 13.8091Z"
                                                    fill="#1A1A1A"
                                                />
                                            </svg>
                                        </a>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <div className="contacts__col relative">
                            <motion.div className="icon-chat_svg" style={{ y }}>
                                <img
                                    src={IconChat}
                                    width="148"
                                    height="119"
                                    alt=""
                                />
                            </motion.div>
                            <div className="contacts__wrapper">
                                <h2 className="contacts__title outlined-text">
                                    {t("contacts_form_title")}
                                    <span>{t("contacts_form_title_span")}</span>
                                </h2>
                                <ContactForm />
                            </div>
                        </div>
                    </div>

                    <DividerLine />
                </div>
            </section>
        </>
    );
};

export default Contacts;
