import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
import common_en from "./translations/en.json";
import common_it from "./translations/it.json";

const resources = {
    it: {
        translation: common_it,
    },
    en: {
        translation: common_en,
    },
};

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: "it",
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
        detection: {
            order: ["localStorage"],
        },
    });

export default i18n;
