import { projectCards } from "../data/data";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { IProject } from "../types/types";

export function useMatchMedia(
    mediaQuery: string,
    initialValue: boolean
): boolean {
    const [isMatching, setIsMatching] = useState(initialValue);
    useEffect(() => {
        const watcher = window.matchMedia(mediaQuery);
        setIsMatching(watcher.matches);
        //@ts-ignore
        const listener = (matches) => {
            setIsMatching(matches.matches);
        };
        watcher.addEventListener
            ? watcher.addEventListener("change", listener)
            : //@ts-ignore
              watcher.addEventListener(listener);
        return () => {
            if (watcher.removeEventListener) {
                return watcher.removeEventListener("change", listener);
            } else {
                //@ts-ignore
                return watcher.removeEventListener(listener);
            }
        };
    }, [mediaQuery]);

    return isMatching;
}

export const useDisableBodyScroll = (state: boolean) => {
    useEffect(() => {
        let htmlEl: HTMLHtmlElement | null = document.querySelector("html");
        if (htmlEl) {
            if (state) {
                htmlEl.style.overflowY = "hidden";
                document.body.style.overflowY = "hidden";
            } else {
                htmlEl.style.overflowY = "unset";
                document.body.style.overflowY = "unset";
            }
        }
    }, [state]);
};

export function filterProjectsByCategory(
    t: Function,
    category: string
): IProject[] {
    let filteredProjects = null;
    if (category === "All") {
        filteredProjects = projectCards(t);
    } else {
        filteredProjects = projectCards(t).filter(
            (project) => project.category === category
        );
    }
    return filteredProjects;
}

export function countProjectsByCategory(
    t: Function,
    category: string
): null | number {
    let count: null | number = null;
    if (category === "All") {
        count = projectCards(t).length;
    } else {
        let arrFiltered = projectCards(t).filter(
            (project) => project.category === category
        );
        count = arrFiltered.length;
    }
    return count;
}

export function checkNameValidity(
    name: string,
    t: Function
): Function | string {
    if (name === "") {
        return t("required_name");
    } else if (name.length < 2) {
        return t("invalid_name");
    }
    return "";
}

export function checkEmailValidity(
    email: string,
    t: Function
): Function | string {
    const emailRegex = new RegExp(
        "^(?=.{1,64}@)[A-Za-z0-9_-]+(\\.[A-Za-z0-9_-]+)*@[^-][A-Za-z0-9-]+(\\.[A-Za-z0-9-]+)*(\\.[A-Za-z]{2,})$"
    );
    if (emailRegex.test(email)) {
        return "";
    } else if (email === "") {
        return t("required_email");
    }
    return t("invalid_email");
}

export function checkMessageValidity(message: string, t: Function): string {
    if (message === "") {
        return t("required_message");
    } else if (message.length < 10) {
        return t("short_message");
    }
    return "";
}

export function showToastAlert(error: string): any {
    toast.clearWaitingQueue();
    return toast.error(error);
}
