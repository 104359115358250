/* experience images imports */
import DesignImg from "../assets/img/design_image.jpg";
import CodingImg from "../assets/img/coding_image.jpg";
import WorkImg from "../assets/img/work_image.jpg";
import LearnImg from "../assets/img/learn_image.jpg";
/* portfolio images imports */
import BenQImg from "../assets/img/benq_projectors.jpg";
import UNCImg from "../assets/img/consumatori.jpg";
import ERImg from "../assets/img/elena_roberto_parrucchieri.jpg";
import FCImg from "../assets/img/franciacorta_map.jpg";
import GDMHImg from "../assets/img/gemelli_gdmh.jpg";
import HMImg from "../assets/img/handmade_mariella.jpg";
import Nik7Img from "../assets/img/nicola_settimo.jpg";
import VDPImg from "../assets/img/vaso_di_pandora.jpg";
import YBImg from "../assets/img/yobee_showcase.jpg";
import CTCImg from "../assets/img/gemelli_ctc.jpg";
import MNTVImg from "../assets/img/musei_mantova_landing.jpg";
import {
    IContact,
    IExperienceCard,
    IMenuItem,
    IProject,
    IProjectCategory,
    ISkill,
} from "../types/types";

export const menuItems = (t: Function): IMenuItem[] => [
    {
        menu: t("about"),
        label: "about",
    },
    {
        menu: t("experience"),
        label: "experience",
    },
    {
        menu: t("skills"),
        label: "skills",
    },
    {
        menu: t("projects_menu"),
        label: "projects",
    },
    {
        menu: t("contacts"),
        label: "contacts",
    },
];

export const experienceCards = (t: Function): IExperienceCard[] => [
    {
        title: "UX/UI",
        description: t("design_description"),
        img: DesignImg,
    },
    {
        title: "Coding",
        description: t("coding_description"),
        img: CodingImg,
    },
    {
        title: "Work",
        description: t("work_description"),
        img: WorkImg,
    },
    {
        title: "Learn",
        description: t("learn_description"),
        img: LearnImg,
    },
];

export const skillsData: ISkill[] = [
    {
        title: "Frontend",
        skills: [
            "HTML",
            "CSS/SASS",
            "Tailwind CSS",
            "JavaScript",
            "TypeScript",
            "React JS",
            "WordPress",
        ],
    },

    {
        title: "Backend",
        skills: ["PHP", "MySQL", "GraphQL"],
    },

    {
        title: "UX/UI",
        skills: ["Figma", "Illustrator"],
    },
];

export const projectsCats = (t: Function): IProjectCategory[] => [
    {
        id: "All",
        name: t("all"),
    },
    {
        id: "Website",
        name: "Website",
    },
    {
        id: "Single page",
        name: "Single page",
    },
    {
        id: "Landing page",
        name: "Landing page",
    },
    {
        id: "Web application",
        name: "Web application",
    },
    {
        id: "E-commerce",
        name: "E-commerce",
    },
];

export const projectCards = (t: Function): IProject[] => [
    {
        id: 1,
        title: "Elena e Roberto",
        description: t("elena_roberto_description"),
        category: "Single page",
        skills: ["HTML", "CSS", "JS"],
        agency: "",
        img: ERImg,
        link: "https://elenarobertoparrucchieri.altervista.org/",
    },
    {
        id: 2,
        title: "Handmade by Mariella",
        description: t("handmade_mariella_description"),
        category: "Website",
        skills: ["HTML", "CSS", "JS", "PHP", "MYSQL"],
        agency: "",
        img: HMImg,
        link: "https://mariellaoldrini.altervista.org/",
    },
    {
        id: 3,
        title: "Showcase Yobee",
        description: t("showcase_yobee_description"),
        category: "Website",
        skills: ["WordPress", "CSS"],
        agency: "Keyformat",
        img: YBImg,
        link: "https://showcase.yobee.it/",
    },
    {
        id: 4,
        title: "BenQ Projectors",
        description: t("benq_description"),
        category: "Landing page",
        skills: ["HTML", "CSS", "JS"],
        agency: "Keyformat",
        img: BenQImg,
        link: "https://www.benq.eu/it-it/proiettorimobile.html",
    },
    {
        id: 5,
        title: "Nicola Settimo",
        description: t("nicola_settimo_description"),
        category: "Website",
        skills: ["HTML", "SASS", "JS", "SEO", "PHP", "MYSQL"],
        agency: "",
        img: Nik7Img,
        link: "https://www.nicolasettimo.it/",
    },
    {
        id: 6,
        title: "Franciacorta Map",
        description: t("franciacorta_map_description"),
        category: "Web application",
        skills: ["React JS", "SASS"],
        agency: "Keyformat",
        img: FCImg,
        link: "https://franciacorta.wine/it/mappa/",
    },
    {
        id: 7,
        title: "Consumatori.it",
        description: t("consumatori_description"),
        category: "Website",
        skills: ["WordPress", "SASS", "JS"],
        agency: "Keyformat",
        img: UNCImg,
        link: "https://www.consumatori.it/",
    },
    {
        id: 8,
        title: "Policlinico Gemelli GDMH",
        description: t("gdmh_description"),
        category: "Landing page",
        skills: ["Wordpress", "CSS", "JS"],
        agency: "Keyformat",
        img: GDMHImg,
        link: "https://gdmh.it/",
    },
    {
        id: 9,
        title: "Vaso di Pandora",
        description: t("vaso_pandora_description"),
        category: "E-commerce",
        skills: ["Wordpress", "WooCommerce", "CSS"],
        agency: "Keyformat",
        img: VDPImg,
        link: "https://vasodipandora.online/",
    },
    {
        id: 10,
        title: "Clinical Trial Center",
        description: t("ctc_description"),
        category: "Website",
        skills: ["Wordpress", "CSS", "JS"],
        agency: "Keyformat",
        img: CTCImg,
        link: "https://clinicaltrialcenter.it/",
    },
    {
        id: 11,
        title: t("musei_mantova_title"),
        description: t("musei_mantova_description"),
        category: "Landing page",
        skills: ["Wordpress", "CSS", "JS"],
        agency: "Keyformat",
        img: MNTVImg,
        link: "https://museimantova.it/",
    },
];

export const contactsInfo = (t: Function): IContact[] => [
    {
        icon: "email",
        info: "info@marcobrambilladev.it",
        link: "mailto:info@marcobrambilladev.it",
    },
    {
        icon: "address",
        info: t("address"),
        link: "",
    },
];
