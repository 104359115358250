import { useRef } from "react";
import { useTranslation } from "react-i18next";
import TitleWrapper from "../../components/titleWrapper/TitleWrapper";
import ExperienceCard from "../../components/experienceCard/ExperienceCard";
import DividerLine from "../../components/dividerLine/DividerLine";
import { motion, useScroll, useTransform } from "framer-motion";
import { useMatchMedia } from "../../utils/utils";

const Experience = () => {
    // Use refs
    let ref = useRef<null | HTMLElement>(null);

    // Internazionalization
    const { t } = useTranslation();

    // Framer Motion configurations
    const { scrollYProgress } = useScroll({
        target: ref,
        offset: ["start start", "end start"],
    });
    const y = useTransform(scrollYProgress, [0, 1], ["0%", "-20%"]);

    // Check resolution on window resize
    const isTabletResolution = useMatchMedia("(min-width:1024px)", true);

    return (
        <section className="experience mb-xl" id="experience" ref={ref}>
            <div className="experience__wrapper mw relative">
                <div className="section section-common mb-s">
                    <TitleWrapper sectionTitle={"Experience"} />

                    <div className="section__upper-wrapper upper-wrapper">
                        <div className="upper-wrapper__row mw">
                            <p>{t("experience_description")}</p>
                        </div>
                    </div>
                </div>

                <div className="experience__content mb-s">
                    <div className="experience__cards">
                        <ExperienceCard />

                        {isTabletResolution && (
                            <motion.div
                                className="experience__decoration"
                                style={{ y }}
                            >
                                <svg
                                    width="148"
                                    height="136"
                                    viewBox="0 0 148 136"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M84.2455 88.9566C98.1425 82.1339 110.089 73.4274 117.838 64.4527C121.561 60.1386 124.11 56.006 125.421 52.1655C126.701 48.3406 126.678 44.9974 125.335 42.2626C123.977 39.4959 121.346 37.4329 117.553 36.1379C113.744 34.811 108.915 34.3006 103.225 34.6078C91.3851 35.2506 77.1908 39.3778 63.2938 46.2006C49.3968 53.0233 37.4507 61.7298 29.7013 70.7045C25.9784 75.0186 23.4292 79.1512 22.1498 82.976C20.8703 86.8009 20.8936 90.1441 22.2362 92.879C23.5946 95.6457 26.2255 97.7087 30.0186 99.0037C33.8273 100.331 38.6559 100.841 44.346 100.534C56.186 99.8909 70.3485 95.7794 84.2455 88.9566ZM62.3096 44.2763C76.4611 37.3287 90.941 33.1402 103.05 32.4835C115.508 31.8135 124.073 34.9494 127.196 41.3096C130.318 47.6698 127.561 56.3638 119.413 65.8109C111.487 74.9902 99.3181 83.8852 85.1667 90.8329C71.0152 97.7805 56.5353 101.969 44.4259 102.626C31.9997 103.28 23.4033 100.16 20.2808 93.7996C17.1582 87.4394 19.9148 78.7454 28.0638 69.2983C36.0208 60.1034 48.1582 51.224 62.3096 44.2763Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M65.1132 120.727C68.7385 118.947 72.4996 115.917 76.2694 111.698C84.2079 102.867 91.3206 89.9428 96.3071 75.2602C101.309 60.6093 103.586 46.0338 102.737 34.1765C102.319 28.5014 101.213 23.7561 99.401 20.1464C97.6211 16.5211 95.2139 14.1904 92.3217 13.2029C89.4295 12.2154 86.0996 12.5873 82.4743 14.3672C82.4425 14.3828 82.4425 14.3828 82.4107 14.3984C78.7854 16.1783 75.0242 19.2088 71.2545 23.4275C63.316 32.2582 56.2033 45.1826 51.2167 59.8652C46.2146 74.516 43.9381 89.0916 44.7867 100.949C45.205 106.624 46.3112 111.369 48.1229 114.979C49.9027 118.604 52.3099 120.935 55.2021 121.922C58.0944 122.91 61.4243 122.538 65.0496 120.758C65.0814 120.743 65.0814 120.743 65.1132 120.727ZM81.4739 12.4904C85.5762 10.4763 89.493 10.0136 92.986 11.1797C99.7187 13.4785 103.89 21.5732 104.814 33.9856C105.708 46.0967 103.382 60.973 98.2829 75.9083C93.1836 90.8436 85.9108 104.004 77.8121 113.072C69.5059 122.359 61.2393 126.181 54.5222 123.914C47.7895 121.615 43.618 113.52 42.6942 101.108C41.7999 88.9968 44.1261 74.1206 49.2254 59.1853C54.3246 44.25 61.5975 31.0891 69.6961 22.022C73.6735 17.5829 77.6578 14.3639 81.4739 12.4904Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M121.115 93.2332C121.147 93.2176 121.147 93.2176 121.178 93.2019C124.804 91.4221 127.134 89.0149 128.122 86.1227C129.109 83.2305 128.737 79.9006 126.958 76.2753C125.178 72.65 122.132 68.857 117.897 65.0554C109.067 57.1169 96.1422 50.0043 81.4596 45.0177C66.8088 40.0156 52.2333 37.7391 40.376 38.5877C34.7327 38.9903 30.0348 40.1128 26.4095 41.8927C26.3777 41.9083 26.3777 41.9083 26.3459 41.9239C22.7206 43.7037 20.3899 46.1109 19.4024 49.0031C18.4149 51.8954 18.7868 55.2253 20.5667 58.8506C22.3465 62.4759 25.3926 66.2688 29.627 70.0704C38.4577 78.0089 51.382 85.1216 66.0646 90.1081C80.7155 95.1103 95.291 97.3867 107.148 96.5381C112.776 96.1037 117.49 95.013 121.115 93.2332ZM25.4727 39.9846C29.2888 38.1111 34.2723 36.9273 40.2169 36.4952C52.328 35.6009 67.2042 37.9271 82.1395 43.0263C97.0748 48.1256 110.236 55.3985 119.303 63.4971C128.559 71.819 132.38 80.0855 130.113 86.8026C127.799 93.5035 119.72 97.7068 107.292 98.5988C95.1807 99.4932 80.3044 97.167 65.3691 92.0677C50.4338 86.9684 37.273 79.6956 28.2058 71.5969C18.9502 63.2751 15.0811 54.9924 17.3954 48.2914C18.609 44.8146 21.3386 42.0143 25.4727 39.9846Z"
                                        fill="black"
                                    />
                                </svg>
                            </motion.div>
                        )}
                    </div>
                </div>

                <DividerLine />
            </div>
        </section>
    );
};

export default Experience;
